import React from 'react'

import "./load.styles.scss"



 function Loader() {
    return (
        <div className="loader">
        <div className="loading"></div>
        </div>
    )
}

export default Loader
