
import cap from "../assets/cap.jpg";
import men from "../assets/elizeu-dias.jpg";
import sneaker from "../assets/boots.jpg";
import women from "../assets/3women.png";

export const Images = {
  cap,
  men,
  sneaker,
  women,
};


//old code

// export const Images = {
// cap: require("../assets/cap.jpg").default,
// men: require("../assets/elizeu-dias.jpg").default,
// sneaker: require("../assets/boots.jpg").default,
// women: require("../assets/3women.png").default
// }