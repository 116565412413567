import React from 'react'

import "./footer.styles.scss"


const Footer = ()=>{
    return(
<div className="footer">
<p className="author">Site built by: <br/>
   Bashar Daseh </p>
    <p className="title">FLAMINGO</p>
  
  

</div>)
}


export default Footer