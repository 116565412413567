import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';

const config = {
  apiKey: "AIzaSyARMYSoUvL5x20b8di0EtysrFOvkPwms5c",
  authDomain: "clothing-ecommerce-843a2.firebaseapp.com",
  projectId: "clothing-ecommerce-843a2",
  storageBucket: "clothing-ecommerce-843a2.appspot.com",
  messagingSenderId: "307966816922",
  appId: "1:307966816922:web:2ba327ccfaa24c5550a523",
  measurementId: "G-8KCMT3LVV4"
};

const app = initializeApp(config);

export const auth = getAuth(app);
export const firestore = getFirestore(app);

export const createUserProfileDocument = async (userAuth, additionalData)=> { 
  if(!userAuth) return;

  if(!userAuth.uid || typeof userAuth.uid !== 'string' || userAuth.uid.length === 0) {
    console.error('userAuth.uid is not defined or not valid');
    return;
  }

  const userRef = doc(firestore, `users/${userAuth.uid}`);

  const snapShot = await getDoc(userRef);

  if (!snapShot.exists()){
    const { displayName, email } = userAuth;
    const createdAt = new Date();

    try{
      await setDoc(userRef, {
        displayName,
        email,
        createdAt,
        ...additionalData
      });
    } catch(error){
      console.log("error creating user", error.message);
    }
  }

  return userRef;
};

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });
export const signInWithGoogle = () => signInWithPopup(auth, provider);
