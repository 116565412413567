import './App.css';
import { Switch, Route, Redirect } from 'react-router-dom';
import Header from './components/header/header';
import { auth, createUserProfileDocument } from "./firebase/firebase.utils";
import { lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser, setAsGuest } from "./redux/user/user.actions";
import HomePage from './pages/homepage/homepage.component';
import Footer from './components/footer/footer';
import SignInPopup from './components/sign-in-popup/sign-in-popup';
import Loader from './components/loader/Loader';
import { onSnapshot } from 'firebase/firestore';

const ShopPage = lazy(() => import('./pages/shop/shop.component'))
const CheckoutPage = lazy(() => import('./pages/checkout/checkout'))
const SignInSignOutPage = lazy(() => import('./pages/sign-in-up/sign-in-out'))

const App = () => {
  const currentUser = useSelector(state => state.user.currentUser);
  const isGuest = useSelector(state => state.user.isGuest);
  const modalShow = useSelector(state => state.user.modalShow);
  
  const dispatch = useDispatch();

  useEffect(() => {
    let unsubscribeFromAuth = null;
    
    unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
      if (userAuth) {
        const userRef = await createUserProfileDocument(userAuth);
        onSnapshot(userRef, snapShot => {
          dispatch(setCurrentUser({
            currentUser: {
              id: snapShot.id,
              ...snapShot.data()
            }
          }));
        });
        dispatch(setAsGuest());
      } else {
        dispatch(setCurrentUser(userAuth));
      }
    });

    return () => unsubscribeFromAuth();
  }, [dispatch]);

  return (
    <div>
      {
        isGuest ? "" : <SignInPopup show={modalShow} />
      }
      <Header />
      <Switch>
        <Suspense fallback={<Loader />}>
          <Route path="/" component={HomePage} exact />
          <Route path="/shop" component={ShopPage} />
          <Route exact path="/checkout" component={CheckoutPage} />
          <Route exact path="/signin" render={() => currentUser ?
            (<Redirect to="/" />) : (<SignInSignOutPage />)} />
        </Suspense>
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
